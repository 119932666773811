<template>
  <section class="section map">
    <div class="tit"><h2 class="wow animate__bounceInLeft">平台业务覆盖区域</h2></div>
    <div ref="echarts"></div>
  </section>
</template>

<script>
import echarts from 'echarts';
import 'echarts/map/js/china';
import {WOW} from 'wowjs';

export default {
  name: "Map"
  , data() {
    return {
      windowWidth: document.body.clientWidth,
      windowHeight: document.body.clientHeight,
    };
  }

  , mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animate__animated',//动画css类（默认为animated）
        offset: 300,//到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init();
    })

    const myChart = echarts.init(this.$refs.echarts);
    const series = [];// 地图展现数据
    // 24个省市经度纬度
    const geoCoordMap = {
      陕西: [109.503789, 35.860026],
      西安: [108.946466, 34.347269],
      甘肃: [103.832478, 36.065465],
      兰州: [103.84044, 36.067321],
      新疆: [87.633473, 43.799238],
      乌鲁木齐: [87.62444, 43.830763],
      内蒙古: [111.772606, 40.823156],
      包头: [109.846544, 40.662929],
      青海: [101.786462, 36.627159],
      西宁: [101.78443, 36.623393],
      宁夏: [106.265605, 38.476878],
      银川: [106.258602, 38.487834], // 106.240023,38.493323
      四川: [104.073467, 30.577543],
      成都: [104.081534, 30.655822],
      重庆: [106.558434, 29.568996],
      西藏: [91.124342, 29.652894],
      拉萨: [91.120789, 29.65005],
      云南: [101.592952, 24.864213],
      昆明: [102.852448, 24.873998],
      贵州: [106.714476, 26.60403],
      贵阳: [106.636577, 26.653325],
      广西: [108.924274, 23.552255],
      南宁: [108.373451, 22.822607],
      山西: [112.515496, 37.866566],
      太原: [112.534919, 37.873219],
      河南: [101.556307, 34.51139],
      郑州: [113.631419, 34.753439],
      湖北: [112.410562, 31.209316],
      武汉: [114.311582, 30.598467],
      湖南: [111.720664, 27.695864],
      长沙: [112.945473, 28.234889],
      江西: [115.676082, 27.757258],
      南昌: [115.864589, 28.689455],
      安徽: [117.33054, 31.734294],
      合肥: [117.233443, 31.826578],
      上海: [121.480539, 31.235929],
      浙江: [120.159533, 30.271548],
      杭州: [120.215503, 30.253087],
      广东: [113.394818, 23.408004],
      广州: [113.271431, 23.135336],
      北京: [116.413384, 39.910925],
      天津: [117.209523, 39.093668],
      河北: [117.220297, 39.173149],
      石家庄: [114.502461, 38.045474],
      唐山: [118.186459, 39.636584],
      黑龙江: [126.642464, 45.756967],
      哈尔滨: [126.642464, 45.756967],
      吉林: [125.3245, 43.886841],
      长春: [125.3245, 43.886841],
      辽宁: [123.429096, 41.796767],
      沈阳: [123.429096, 41.796767],
      山东: [117.000923, 36.675807],
      济南: [117.000923, 36.675807],
      江苏: [118.767413, 32.041544],
      南京: [118.767413, 32.041544],
      福建: [119.306239, 26.075302],
      福州: [119.306239, 26.075302],
      海南: [110.33119, 20.031971],
      海口: [110.33119, 20.031971],
    };
    // const fromdata = '天津';// 默认飞线原点
    const fromdata = '银川';// 默认飞线原点
    // 初始化飞线数据
    // const XAData = [
    //   [{name: '天津'}, {name: '哈尔滨'}],
    //   [{name: '天津'}, {name: '长春'}],
    //   [{name: '天津'}, {name: '沈阳'}],
    //   [{name: '天津'}, {name: '石家庄'}],
    //   [{name: '天津'}, {name: '郑州'}],
    //   [{name: '天津'}, {name: '济南'}],
    //   [{name: '天津'}, {name: '西安'}],
    //   [{name: '天津'}, {name: '合肥'}],
    //   [{name: '天津'}, {name: '南京'}],
    //   [{name: '天津'}, {name: '杭州'}],
    //   [{name: '天津'}, {name: '南昌'}],
    //   [{name: '天津'}, {name: '福州'}],
    //   [{name: '天津'}, {name: '上海'}],
    //   [{name: '天津'}, {name: '北京'}],
    //   [{name: '天津'}, {name: '天津'}],
    //   [{name: '天津'}, {name: '南宁'}],
    //   [{name: '天津'}, {name: '武汉'}],
    //   [{name: '天津'}, {name: '乌鲁木齐'}],
    //   [{name: '天津'}, {name: '西宁'}],
    //   [{name: '天津'}, {name: '兰州'}],
    //   [{name: '天津'}, {name: '银川'}],
    //   [{name: '天津'}, {name: '包头'}],
    //   [{name: '天津'}, {name: '太原'}],
    //   [{name: '天津'}, {name: '成都'}],
    //   [{name: '天津'}, {name: '重庆'}],
    //   [{name: '天津'}, {name: '昆明'}],
    //   [{name: '天津'}, {name: '贵阳'}],
    //   [{name: '天津'}, {name: '广州'}],
    //   [{name: '天津'}, {name: '长沙'}],
    // ];
const XAData = [
      [{name: '银川'}, {name: '哈尔滨'}],
      [{name: '银川'}, {name: '长春'}],
      [{name: '银川'}, {name: '沈阳'}],
      [{name: '银川'}, {name: '石家庄'}],
      [{name: '银川'}, {name: '郑州'}],
      [{name: '银川'}, {name: '济南'}],
      [{name: '银川'}, {name: '西安'}],
      [{name: '银川'}, {name: '合肥'}],
      [{name: '银川'}, {name: '南京'}],
      [{name: '银川'}, {name: '杭州'}],
      [{name: '银川'}, {name: '南昌'}],
      [{name: '银川'}, {name: '福州'}],
      [{name: '银川'}, {name: '上海'}],
      [{name: '银川'}, {name: '北京'}],
      [{name: '银川'}, {name: '天津'}],
      [{name: '银川'}, {name: '南宁'}],
      [{name: '银川'}, {name: '武汉'}],
      [{name: '银川'}, {name: '乌鲁木齐'}],
      [{name: '银川'}, {name: '西宁'}],
      [{name: '银川'}, {name: '兰州'}],
      [{name: '银川'}, {name: '银川'}],
      [{name: '银川'}, {name: '包头'}],
      [{name: '银川'}, {name: '太原'}],
      [{name: '银川'}, {name: '成都'}],
      [{name: '银川'}, {name: '重庆'}],
      [{name: '银川'}, {name: '昆明'}],
      [{name: '银川'}, {name: '贵阳'}],
      [{name: '银川'}, {name: '广州'}],
      [{name: '银川'}, {name: '长沙'}],
    ];
    const convertData1 = function (data) {
      const res = [];
      for (let i = 0; i < data.length; i++) {
        const dataItem = data[i];
        const fromCoord = geoCoordMap[dataItem[0].name];
        const toCoord = geoCoordMap[dataItem[1].name];
        if (fromCoord && toCoord) {
          res.push({
            fromName: dataItem[0].name, toName: dataItem[1].name, coords: [fromCoord, toCoord],
          });
        }
      }
      return res;
    };

    [[fromdata, XAData]].forEach((item, i) => {
      series.push(
          {
            type: 'lines',
            zlevel: 15,
            effect: {
              show: true, period: 4, trailLength: 0, symbol: 'arrow', symbolSize: 7,
            },
            lineStyle: {
              normal: {
                width: 1.2, opacity: 0.6, curveness: 0.2, color: '#f19000',
              },
            },
            data: convertData1(item[1]),
          },
          // 出发点
          {
            name: '天津',
            // name: '银川',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            zlevel: 30,
            rippleEffect: {
              period: 1, brushType: 'stroke', scale: 0,
            },
            // symbol: 'circle',
            symbol: 'image://car.png',
            symbolSize:[60,60],
            // symbolSize(val) {
            //   return 4 + val[2] / 10;
            // },
            itemStyle: {
              normal: {show: false},
            },
            tooltip: {
              show: false,
            },
            data: [{
              name: fromdata, value: geoCoordMap[item[0]].concat([100]),
            }],
          },
          /* 到达点 */
          {
            name: '长沙',
            type: 'effectScatter',
            coordinateSystem: 'geo',
            rippleEffect: {
              period: 4, brushType: 'stroke', scale: 4,
            },
            zlevel: 15,
            label: {
              normal: {
                show: false,
              },
            },
            tooltip: {
              show: false,
            },
            symbol: 'circle',
            symbolSize: 4,
            itemStyle: {
              normal: {
                color: '#f19000',
              },
            },
            data: item[1].map((dataItem) => ({
              name: dataItem[1].name,
              value: geoCoordMap[dataItem[1].name].concat([dataItem[1].name]),
              tooltip: {
                formatter: `${dataItem[0].name}--${dataItem[1].name}：${dataItem[1].value}`,
              },
            })),
          },
      );
    });
    const option = {
      backgroundColor: '#fff',
      tooltip: {
        trigger: 'item',
        formatter(params) {
          if (params.value) {
            return `${params.name} : ${params.value[2]}`;
          }
          return params.name;
        },
      },
      geo: {
        type: 'map',
        map: 'china',
        zlevel: 10,
        layoutCenter: ['10%'],
        roam: false,
        layoutSize: '100%',
        zoom: 1,
        label: {
          normal: {
            show: true, // 地图上的省份名称是否显示
            textStyle: {
              fontSize: 12,
              color: '#43d0d6',
            },
          },
          emphasis: {
            show: true,
          },
        },
        itemStyle: {
          normal: {
            color: '#fff',
            borderWidth: 1.1,
            borderColor: '#43d0d6',
          },
          emphasis: {
            areaColor: '#43d0d6',
          },
        },
      },
      series,
    };
    myChart.setOption(option);
    myChart.resize({
      height: 800
    })
    window.onresize = () => {

      return (() => {
        this.$nextTick(() => {
          let width = document.body.clientWidth
          let height = "600"
          if (width < 620) {
            height = 300;
          }
          myChart.resize({
            width: width,
            height: height
            //height:document.body.clientHeight
          })
        })
      })()
    }
  },


}
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  //& #echarts { width: 100%; }
}
.tit {
  text-align: center;
  background-color: #fff;
  padding-top: 30px;
  h2 {
    letter-spacing: 2px;
  }
}
</style>